import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import * as Yup from "yup"
import axios from "axios"
import { Formik } from "formik"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import Layout from "../components/layout"
import Head from "../components/head"
import * as styles from "./index.module.scss"
const URL = "https://assets.itxs.jp/wp-json"
const USER = "itxs-owner"
const PASSWORD = "jdss TTgn iW0E z4Kh OcJ6 Y4r5"
const TOKEN =
  typeof window !== "undefined" && window.btoa(`${USER}:${PASSWORD}`) // Convert Base64
const CF7_ID = "2932"
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const formSchema = Yup.object().shape({
  yourName: Yup.string().required("必須項目です。入力をお願いします。"),
  yourBirth: Yup.string().required("必須項目です。入力をお願いします。"),
  yourEmail: Yup.string()
    .email("メールアドレスの形式で入力してください。")
    .required("必須項目です。入力をお願いします。"),
  yourTelPhone: Yup.string()
    .matches(phoneRegExp, "電話番号の形式に誤りがあります")
    .required("必須項目です。入力をお願いします。"),
  yourFileRireki: Yup.mixed().required("必須項目です。入力をお願いします。"),
  yourFileKeireki: Yup.mixed().required("必須項目です。入力をお願いします。"),
  acceptanceConfirm: Yup.boolean().oneOf(
    [true],
    "必須項目です。入力をお願いします。"
  ),
})
// function convertJsontoUrlencoded(obj) {
//   let str = []
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key)) {
//       str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
//     }
//   }
//   return str.join("&")
// }
const IndexPage = props => {
  const backgroundImageEnvironments = convertToBgImage(
    getImage(props.data.backgroundImageEnvironments.childImageSharp)
  )
  const backgroundImageFaq = convertToBgImage(
    getImage(props.data.backgroundImageFaq.childImageSharp)
  )
  const [state, setState] = React.useState(null || "")
  const yourFileRirekiRef = React.useRef()
  const yourFileKeirekiRef = React.useRef()
  return (
    <Layout>
      <Head
        title="採用情報"
        description="株式会社アイテクスの採用情報をご案内致します。"
      />
      <section className="hero is-halfheight">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">採用情報</h1>
            <h2 className="subtitle">Recruit</h2>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <div className="container">
          <StaticImage
            src="../../content/assets/16x9-tougou-heihachiro.jpg"
            alt="採用情報 東郷平八郎"
            placeholder="blurred"
            layout="fullWidth"
          />
          <h1 className="title mt-6">革新的な技術で世の中を動かす</h1>
          <h2 className="subtitle">Innovation</h2>
          <div className="content">
            <p>
              当社は地域に密着しITのエキスパートとして「楽する仕事」が出来る環境を提供しています。
              <br />
              アイテクスの仲間として、一緒にチャレンジしませんか？
            </p>
          </div>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-variable is-5">
            <div className="column">
              <h1 className="title">募集職種</h1>
              <h2 className="subtitle">Jobs</h2>
              <div className="content">
                <h3>エンジニア</h3>
                <p>
                  システムやウェブサービスの開発、リリース後の運用・改善を行います。
                </p>
                <p>
                  システム開発・Webシステム開発（主にC#）
                  <br />
                  自社開発もしくは、請負開発
                  <br />
                  <strong>※転勤や派遣、常駐作業はありません。</strong>
                </p>
              </div>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <div className="container">
          <h1 className="title">募集要項</h1>
          <h2 className="subtitle">Recruitment</h2>
          <div className="content">
            <div className="table-container">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="row">職種</th>
                    <td>
                      システムエンジニア
                      <br />
                      プログラマ
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">業務内容</th>
                    <td>
                      システム開発・Webシステム開発（主にC#）
                      <br />
                      サービス、パッケージ製品企画・開発
                      <br />
                      ITインフラ環境構築／運用支援・保守／コンサルティング
                      <br />
                      ITソフトウェア運用支援／コンサルティング
                      <br />
                      IT関連のアウトソーシング
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">アピールポイント</th>
                    <td>
                      天文館電停から徒歩すぐ！
                      <br />
                      完全週休２日制！
                      <br />
                      有給あり
                      <br />
                      在宅勤務可能です（必要に応じて、いつでも出社出来ることが前提）
                      <br />
                      テレワーク可能なため勤務時間は調整できます
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">採用条件</th>
                    <td>
                      システム開発/Web開発: 3年以上（必須）
                      <br />
                      普通一種免許（ＡＴ限定可）
                      <br />
                      IT（プログラム制作や構築等）が大好きなこと
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">歓迎要件</th>
                    <td>
                      以下のいずれかの経験がある方
                      <br />
                      C#、Java、VB.NET、Access、PHP
                      <br />
                      Oracle、SQLServer、PostgreSQL、MySQL、MariaDB
                      <br />
                      Windows、Linux（RHEL、CentOS）、Android、iOSおよび、関連スクリプト
                      <br />
                      WordPress
                      <br />
                      クラウドサービス（AWSやAzure等）の構築・運用
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">求める人物像</th>
                    <td>
                      <ol>
                        <li>
                          会社の理念・思想を共感し実行出来る方
                          <div className="box mr-auto my-3">
                            【企業理念】
                            <br />
                            毎日を楽しく幸せに、元気で楽しい社会をつくる
                            <br />
                            【経営方針】
                            <br />
                            1. 気配り・心配りを持って行動すること
                            <br />
                            2. 全ての活動において楽しさを追求すること
                            <br />
                            3.
                            社員・パートナー・お客様は全て平等に大切にすること
                            <br />
                            4. 毎日、自分の成長を実感すること
                            <br />
                            5. 全ての活動を支えるための利益を生み出すこと
                          </div>
                        </li>
                        <li>
                          IT技術が好きで、新しい技術やスキルの習得に積極的な方
                        </li>
                        <li>人とのコミュニケーションを大切にできる方</li>
                        <li>
                          仕事とプライベートの切り替えをコントロール出来る方
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">勤務時間・曜日</th>
                    <td>
                      09:00～18:00 （休憩1時間）
                      <br />
                      もしくは
                      <br />
                      10:00～19:00 （休憩1時間）
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">交通アクセス</th>
                    <td>
                      鹿児島（在宅勤務可能）
                      <br />
                      <strong>※転勤なし</strong>
                      <br />
                      鹿児島天文館電停より徒歩すぐ！
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">雇用形態</th>
                    <td>
                      正社員
                      <br />
                      <strong>※試用期間あり</strong>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">想定給与</th>
                    <td>20万円～35万円</td>
                  </tr>
                  <tr>
                    <th scope="row">賞与</th>
                    <td>
                      決算賞与
                      <br />
                      <strong>※会社の業績および、個人の活躍に応じて支給</strong>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">休日・休暇</th>
                    <td>
                      完全週休2日制
                      <br />
                      祝日
                      <br />
                      年末年始休暇
                      <br />
                      夏季休暇
                      <br />
                      慶弔休暇
                      <br />
                      介護休暇
                      <br />
                      産休・育児休暇(実績あり)
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">福利厚生</th>
                    <td>
                      社会保険完備（雇用、労災、健康、厚生年金）
                      <br />
                      バースデー休暇制度
                      <br />
                      配偶者誕生日祝い金制度
                      <br />
                      恋人作り応援特別休暇制度
                      <br />
                      スキルアップ支援制度
                      <br />
                      など、他多数あり
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">会社の雰囲気</th>
                    <td>風通しが良く、経営者とも気軽に話せる雰囲気です</td>
                  </tr>
                  <tr>
                    <th scope="row">必要書類</th>
                    <td>
                      履歴書
                      <br />
                      職務経歴書
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container has-text-centered">
          <h1 className="title">採用の流れ</h1>
          <h2 className="subtitle">Flow</h2>
          <div className="columns is-variable is-5">
            <div className="column">
              <StaticImage
                src="../../content/assets/recruit-entry.png"
                alt="エントリー"
                placeholder="blurred"
                layout="fullWidth"
              />
              <p>エントリー</p>
            </div>
            <div className="column">
              <StaticImage
                src="../../content/assets/recruit-selection.png"
                alt="書類選考"
                placeholder="blurred"
                layout="fullWidth"
              />
              <p>書類選考</p>
            </div>
            <div className="column">
              <StaticImage
                src="../../content/assets/recruit-meet.png"
                alt="1次面談"
                placeholder="blurred"
                layout="fullWidth"
              />
              <p>1次面談</p>
            </div>
            <div className="column">
              <StaticImage
                src="../../content/assets/recruit-handshake.png"
                alt="最終面談"
                placeholder="blurred"
                layout="fullWidth"
              />
              <p>最終面談</p>
            </div>
          </div>
          <p>
            選考や勤務環境に関する「
            <Link to="/faq/">よくあるご質問</Link>」や「
            <Link to="/environments/">勤務環境／ワークサポート</Link>
            」のページもご参照ください。
          </p>
        </div>
      </section>
      <section className="section is-medium has-background-white-bis">
        <div className="container is-max-desktop">
          <div className="has-text-centered">
            <h1 className="title">応募フォーム</h1>
            <h2 className="subtitle">Entry</h2>
            <div className="content">
              <p>以下のフォームよりお願いいたします。</p>
            </div>
          </div>
          <div className="has-max-width-600">
            <Formik
              initialValues={{
                yourName: "",
                yourBirth: "",
                yourTelPhone: "",
                yourEmail: "",
                yourFileRireki: null,
                yourFileKeireki: null,
                acceptanceConfirm: false,
              }}
              validationSchema={formSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const data = new FormData()
                data.append("yourName", values.yourName)
                data.append("yourBirth", values.yourBirth)
                data.append("yourTelPhone", values.yourTelPhone)
                data.append("yourEmail", values.yourEmail)
                data.append("yourFileRireki", values.yourFileRireki)
                data.append("yourFileKeireki", values.yourFileKeireki)
                data.append("acceptanceConfirm", values.acceptanceConfirm)
                const submitData = async () => {
                  try {
                    const result = await axios({
                      url: `${URL}/contact-form-7/v1/contact-forms/${CF7_ID}/feedback`,
                      // headers: {
                      //   Authorization: `Basic ${TOKEN}`,
                      //   "Content-Type":
                      //     "application/x-www-form-urlencoded; charset=utf-8",
                      // },
                      headers: {
                        Authorization: `Basic ${TOKEN}`,
                        "Content-Type": "multipart/form-data; charset=utf-8",
                      },
                      method: "POST",
                      //data: convertJsontoUrlencoded(values),
                      data: data,
                    })
                    setState(result.data.message)
                    setSubmitting(false)
                    yourFileRirekiRef.current.value = null
                    yourFileKeirekiRef.current.value = null
                    resetForm({})
                    document.getElementById("acceptanceConfirm").checked = false
                  } catch (error) {
                    setState("送信に失敗しました。再度お試しください。")
                  }
                }
                submitData()
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="field">
                    <label className="label" htmlfor="yourName">
                      お名前<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourName && touched.yourName && "is-danger"
                        }`}
                        type="text"
                        name="yourName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourName}
                        placeholder="山田 太郎"
                      />
                      {errors.yourName && touched.yourName && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourName}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlfor="yourBirth">
                      生年月日<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourBirth && touched.yourBirth && "is-danger"
                        }`}
                        type="date"
                        name="yourBirth"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourBirth}
                        placeholder="2021/11/01"
                      />
                      {errors.yourBirth && touched.yourBirth && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourBirth}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlfor="yourTelPhone">
                      電話番号<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourTelPhone &&
                          touched.yourTelPhone &&
                          "is-danger"
                        }`}
                        type="text"
                        name="yourTelPhone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourTelPhone}
                        placeholder="050-5213-9261"
                      />
                      {errors.yourTelPhone && touched.yourTelPhone && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">
                            {errors.yourTelPhone}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlfor="yourEmail">
                      メールアドレス
                      <span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div className="control has-icons-right">
                      <input
                        className={`input ${
                          errors.yourEmail && touched.yourEmail && "is-danger"
                        }`}
                        type="text"
                        name="yourEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.yourEmail}
                        placeholder="example@itxs.jp"
                      />
                      {errors.yourEmail && touched.yourEmail && (
                        <>
                          <span className="icon is-small is-right">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                          </span>
                          <p className="help is-danger">{errors.yourEmail}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlfor="yourFileRireki">
                      履歴書<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div>
                      <input
                        type="file"
                        name="yourFileRireki"
                        onChange={event => {
                          setFieldValue(
                            "yourFileRireki",
                            event.currentTarget.files[0]
                          )
                        }}
                        onBlur={handleBlur}
                        ref={yourFileRirekiRef}
                      />
                      {errors.yourFileRireki && touched.yourFileRireki && (
                        <>
                          <p className="help is-danger">
                            <span className="icon is-small is-right">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </span>
                            {errors.yourFileRireki}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlfor="yourFileKeireki">
                      職務経歴書<span className="tag is-danger ml-1">必須</span>
                    </label>
                    <div>
                      <input
                        type="file"
                        name="yourFileKeireki"
                        onChange={event => {
                          setFieldValue(
                            "yourFileKeireki",
                            event.currentTarget.files[0]
                          )
                        }}
                        onBlur={handleBlur}
                        ref={yourFileKeirekiRef}
                      />
                      {errors.yourFileKeireki && touched.yourFileKeireki && (
                        <>
                          <p className="help is-danger">
                            <span className="icon is-small is-right">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </span>
                            {errors.yourFileKeireki}
                          </p>
                        </>
                      )}
                    </div>
                    <div>
                      ※ファイルの容量は5MBまで、ファイル形式は「doc、docx、xls、xlsx、pdf」のいずれか。
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <label className="checkbox">
                        <input
                          id="acceptanceConfirm"
                          type="checkbox"
                          name="acceptanceConfirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.acceptanceConfirm}
                        />
                        「<Link to="/privacy-policy">プライバシーポリシー</Link>
                        」および「
                        <Link to="/security">情報セキュリティ基本方針</Link>
                        」を必ずご覧いただき、同意の上お問い合わせください。
                      </label>
                      {errors.acceptanceConfirm && touched.acceptanceConfirm && (
                        <>
                          <p className="help is-danger">
                            <span className="icon is-small">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                            </span>
                            {errors.acceptanceConfirm}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        className="button is-primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        送信
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            {state ? (
              <div className="notification is-info is-light mt-5">{state}</div>
            ) : null}
          </div>
        </div>
      </section>
      <div className="columns is-gapless mb-0">
        <div className="column">
          <Link className={styles.banner} to="/faq/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>よくあるご質問</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageFaq}
              preserveStackingContext
            ></BackgroundImage>
          </Link>
        </div>
        <div className="column">
          <Link className={styles.banner} to="/environments/">
            <div className={styles.banner__cover}></div>
            <div className={styles.banner__label}>勤務環境／ワークサポート</div>
            <BackgroundImage
              Tag="div"
              className={styles.banner__image}
              {...backgroundImageEnvironments}
              preserveStackingContext
            ></BackgroundImage>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
export const pageQuery = graphql`
  query recruit {
    backgroundImageEnvironments: file(
      relativePath: { eq: "16x9-segodon.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    backgroundImageFaq: file(relativePath: { eq: "16x9-sakurajima.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`
